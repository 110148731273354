import { render, staticRenderFns } from "./gifts.vue?vue&type=template&id=4ad2436d"
import script from "./gifts.vue?vue&type=script&lang=js"
export * from "./gifts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppTip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tip.vue').default,AppCheckoutItemSelector: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/checkout/ItemSelector.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default})
