
import { mapGetters } from 'vuex';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';
import { user } from '@/mixins/apollo';

export default {
  name: 'AppWillEstateSelector',
  mixins: [user],
  props: {
    value: {
      default: null,
      type: String,
    },
  },
  computed: {
    ...mapGetters('user/contacts', ['contactsWithRelationship']),
    options() {
      const hasPartners = this.contactsWithRelationship([
        RELATIONSHIP_TYPE.PARTNER,
      ]).length;
      const childContacts = this.contactsWithRelationship([
        RELATIONSHIP_TYPE.CHILD,
      ]);

      const hasChildren = childContacts.length;
      const hasMultipleChildren = childContacts.length > 1;
      const options = [
        hasPartners
          ? {
              label: this.$t('components.estateSelector.myPartner.label'),
              description: this.$t(
                'components.estateSelector.myPartner.description'
              ),
              value: 'partner',
            }
          : null,
        hasChildren
          ? {
              label: hasMultipleChildren
                ? this.$t('components.estateSelector.myChildren.label')
                : this.$t('components.estateSelector.myChild.label'),
              description: hasMultipleChildren
                ? this.$t('components.estateSelector.myChildren.description')
                : this.$t('components.estateSelector.myChild.description'),
              value: 'children',
            }
          : null,
        {
          label: this.$t('components.estateSelector.other.label'),
          description: this.$t('components.estateSelector.other.description'),
          value: 'custom',
        },
      ].filter(Boolean);
      return options;
    },
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
  },
};
