
import { mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'AppSnowflakesUser',
  mixins: [will],
  props: {
    letters: {
      default: 1,
      type: Number,
    },
  },
  computed: {
    ...mapGetters('user/contacts', ['userDetails']),
    userInitial() {
      const firstName = this.userDetails.firstName;
      const lastName = this.userDetails.lastName;
      const initials =
        (firstName ? firstName.charAt(0) : '') +
        (lastName ? lastName.charAt(0) : '');
      return initials.toUpperCase().substring(0, this.letters);
    },
  },
};
