
import { mapGetters, mapActions } from 'vuex';
import {
  isDateOver18,
  extractErrorMessages,
  contactNameOrDefault,
  countryOptions,
} from '@/utilities';

import {
  IDENTIFIER_TYPE,
  COUNTRY,
  RELATIONSHIP_TYPE,
  AU_STATE_OPTIONS,
} from '@/utilities/constants';

export default {
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    contactLabel: {
      type: String,
      default: null,
    },
    relationshipType: {
      type: String,
      default: null,
    },
    identifierRequirements: {
      type: Array,
      default: () => [],
      validator: (value) => {
        return value.every((item) => {
          return Object.values(IDENTIFIER_TYPE).includes(item);
        });
      },
    },
    overEighteen: {
      type: Boolean,
      default: false,
    },
    emailRequiredForInvite: {
      type: Boolean,
      default: false,
    },
    valueLocked: {
      type: Boolean,
      default: false,
    },
    partnerId: {
      type: String,
      default: null,
    },
  },
  data() {
    const getInitialSwitchValue = (identifier, defaultValue) => {
      return this.identifierRequirements.includes(identifier)
        ? true
        : defaultValue;
    };
    return {
      RELATIONSHIP_TYPE,
      AU_STATE_OPTIONS,
      modelContact: this.mapPropsContactToModelContact(this.contact),
      haveDob: !this.contact?.id
        ? this.identifierRequirements.includes(IDENTIFIER_TYPE.DOB)
        : getInitialSwitchValue(
            IDENTIFIER_TYPE.DOB,
            !!this.contact.dateOfBirth
          ),
      havePhoneNumber: getInitialSwitchValue(
        IDENTIFIER_TYPE.PHONE,
        !!this.contact.defaultPhoneNumber?.value
      ),
      haveEmailAddress: getInitialSwitchValue(
        IDENTIFIER_TYPE.EMAIL,
        this.emailRequiredForInvite || !!this.contact.defaultEmailAddress
      ),
      haveAddress: getInitialSwitchValue(
        IDENTIFIER_TYPE.ADDRESS,
        !!this.contact.residentialAddress?.streetAddress
      ),
      isPartnerGuardian:
        !this.contact?.id || !this.partnerId
          ? null
          : this.contact.relationships.some(
              (relationship) =>
                relationship.fromPersonId === this.partnerId &&
                RELATIONSHIP_TYPE.CHILD === relationship.type
            ),
      notifyContact: false,
      notifyContactAlreadySent: false,
      loading: false,
      formIsInvalid: false,
      formHasBeenValidated: false,
      error: null,
      contactInUseModalIsVisible: false,
      deletecontactModalIsVisible: false,
      contactInUseRoles: [],
      contactCurrentRelationshipWithUser: this.contact?.id
        ? this.contact.relationshipWithUser
        : null,
      relationshipModalIsVisible: false,
      newRelationship: null,
      countryOptions,
    };
  },
  computed: {
    ...mapGetters(['userId', 'token']),
    ...mapGetters('user/contacts', ['userDetails', 'userPartner']),
    ...mapGetters('invites', ['invites']),
    formIsValid() {
      return !this.backendValidationAlert && !this.formIsInvalid;
    },
    title() {
      if (this.contact?.id) {
        return `${this.$t('components.contactForm.editing')} ${this.shortName}`;
      }
      return `${this.$t('commons.text.add')} ${
        this.contactLabel || this.$t('components.contactForm.aContact')
      }`;
    },
    subtitle() {
      let role = 'contact';
      switch (this.relationshipType) {
        case RELATIONSHIP_TYPE.PARTNER:
          role = 'partner';
          break;
        case RELATIONSHIP_TYPE.CHILD:
          role = 'child';
          break;
      }
      return this.$t('components.contactForm.whatIsThisRoleName', { role });
    },
    ctaText() {
      return this.contact?.id
        ? this.$t('components.contactForm.save')
        : this.$t('components.contactForm.add');
    },
    atLeastOneIdentifier() {
      return (
        this.haveDob ||
        this.havePhoneNumber ||
        this.haveEmailAddress ||
        this.haveAddress
      );
    },
    contactUnder18: {
      get() {
        const above18 = this.modelContact?.ageEighteenOrAbove;
        return above18 === null ? null : !above18;
      },
      set(value) {
        this.modelContact.ageEighteenOrAbove = !value;
      },
    },
    contactIsAustralian() {
      return this.modelContact?.residentialAddress?.country === COUNTRY.AU;
    },
    postcodeRules() {
      return this.modelContact.residentialAddress.country === COUNTRY.AU
        ? 'required|postcode'
        : 'required|max:10';
    },
    notifyContactIsAllowed() {
      if (this.notifyContactAlreadySent) {
        return false;
      }
      if (this.contact?.id && this.contact?.defaultEmailAddress) {
        if (this.invite) {
          return !this.invite.is24HoursFromNow;
        }
      }

      if (
        this.modelContact?.dateOfBirth &&
        !isDateOver18(this.modelContact.dateOfBirth)
      ) {
        return false;
      }
      if (
        !this.modelContact?.dateOfBirth &&
        this.modelContact.ageEighteenOrAbove === false
      ) {
        return false;
      }

      return true;
    },
    contactForSubmit() {
      const contact = { ...this.modelContact };
      if (!this.haveDob) {
        contact.dateOfBirth = null;
      }
      if (!this.havePhoneNumber) {
        contact.defaultPhoneNumber = null;
      }
      if (!this.haveEmailAddress) {
        contact.defaultEmailAddress = null;
      }
      if (!this.haveAddress) {
        contact.residentialAddress = null;
      }
      contact.notifyContact = this.notifyContactIsAllowed && this.notifyContact;
      contact.isPartnerGuardian = this.showPartnerGuardianQuestion
        ? this.isPartnerGuardian
        : null;
      contact.partnerId = this.userPartner?.id;

      return contact;
    },
    hidePartnerRelationship() {
      return (
        !!this.partnerId &&
        this.modelContact.relationshipWithUser !== RELATIONSHIP_TYPE.PARTNER
      );
    },
    showPartnerGuardianQuestion() {
      return (
        !!this.partnerId &&
        this.modelContact.relationshipWithUser === RELATIONSHIP_TYPE.CHILD
      );
    },
    shortName() {
      return contactNameOrDefault(
        this.modelContact,
        this.$t('components.contactForm.aContact')
      );
    },
    dobRequired() {
      return this.identifierRequirements.includes(IDENTIFIER_TYPE.DOB);
    },
    relationshipTypeRequireOver18() {
      return [
        RELATIONSHIP_TYPE.EXECUTOR,
        RELATIONSHIP_TYPE.GUARDIAN,
        RELATIONSHIP_TYPE.ATTORNEY,
        RELATIONSHIP_TYPE.PARTNER,
      ].includes(this.relationshipType);
    },
    mustBeOver18() {
      return this.relationshipTypeRequireOver18 || this.overEighteen;
    },
    dobRules() {
      const rules = ['beforeTomorrow', 'after1900'];
      if (this.dobRequired) {
        rules.push('required');
      }
      if (this.mustBeOver18) {
        rules.push('over18');
      }
      return rules.join('|');
    },
    phoneNumberRequired() {
      return this.identifierRequirements.includes(IDENTIFIER_TYPE.PHONE);
    },
    emailRequired() {
      return (
        this.notifyContactAvailableAndChecked ||
        this.identifierRequirements.includes(IDENTIFIER_TYPE.EMAIL) ||
        this.emailRequiredForInvite
      );
    },
    emailDisabled() {
      return (
        this.emailRequired || (this.invite && !this.notifyContactIsAllowed)
      );
    },
    emailRules() {
      return this.emailRequired || this.haveEmailAddress
        ? 'required|email'
        : 'email';
    },
    addressRequired() {
      return this.identifierRequirements.includes(IDENTIFIER_TYPE.ADDRESS);
    },
    backendValidationAlert() {
      if (this.error) {
        return this.error;
      }
      return null;
    },
    notifyContactAvailableAndChecked() {
      return this.notifyContactIsAllowed && this.notifyContact;
    },
    invite() {
      return this.invites.find(
        (invite) => invite.email === this.modelContact.defaultEmailAddress
      );
    },
    emailAndPhoneRequiredToNotifyText() {
      return this.$ff.contactSelectorInviteAlternativeText()
        ? this.$t('components.contactForm.emailAndPhoneRequiredToNotifyAlt')
        : this.$t('components.contactForm.emailAndPhoneRequiredToNotify');
    },
  },
  watch: {
    formIsInvalid(value) {
      if (this.formHasBeenValidated && !value) {
        this.formHasBeenValidated = false;
      }
    },
    modelContact: {
      handler() {
        this.error = null;
      },
      deep: true,
    },
    notifyContact(value) {
      this.error = null;
      if (value) {
        this.haveEmailAddress = true;
      } else {
        this.haveEmailAddress = !!this.modelContact.defaultEmailAddress;
      }
    },
    notifyContactIsAllowed: {
      handler(value) {
        if (value === false && this.notifyContact === true) {
          this.notifyContact = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const invite = this.invites.find(
      (invite) => invite.email === this.modelContact.defaultEmailAddress
    );
    if (invite?.is24HoursFromNow) {
      this.notifyContact = true;
      this.notifyContactAlreadySent = true;
    }
  },
  methods: {
    ...mapActions('user/contacts', [
      'getDirectoryPerson',
      'createDirectoryPerson',
      'updateDirectoryPerson',
      'deleteDirectoryPerson',
      'validateDeleteDirectoryPerson',
    ]),
    ...mapActions('invites', ['getInvites']),
    close() {
      this.$emit('close');
    },
    mapPropsContactToModelContact(contact) {
      const modelContact = JSON.parse(JSON.stringify(contact));
      if (!modelContact?.residentialAddress?.country) {
        modelContact.residentialAddress = {
          streetAddress: '',
          locality: '',
          region: '',
          country: this.userDetails?.residentialAddress?.country || COUNTRY.AU,
        };
      }
      if (!modelContact.defaultPhoneNumber) {
        modelContact.defaultPhoneNumber = {
          prefix: '+61',
          value: '',
        };
      }
      if (!modelContact.relationshipWithUser) {
        modelContact.relationshipWithUser = this.relationshipType || null;
      }
      if (!this.haveDob && modelContact.ageEighteenOrAbove == null) {
        modelContact.ageEighteenOrAbove = null;
      }
      return modelContact;
    },
    async submit() {
      this.error = null;
      if (this.formIsInvalid) {
        this.formHasBeenValidated = true;
        this.$refs.formWrapper.validateForm();
        return false;
      } else if (
        ![
          this.haveDob,
          this.havePhoneNumber,
          this.haveEmailAddress,
          this.haveAddress,
        ].some((identifier) => identifier)
      ) {
        this.error = this.$t('errors.directory.person.identifier.missing');
        return false;
      }

      this.loading = true;
      try {
        if (!this.contactForSubmit.id) {
          this.contactForSubmit.ownerId =
            this.contactForSubmit.ownerId || this.userId;
          const createdContact = await this.createDirectoryPerson(
            this.contactForSubmit
          );
          this.$emit('contactCreated', createdContact);
        } else {
          const updatedContact = await this.updateDirectoryPerson(
            this.contactForSubmit
          );
          this.$emit('contactUpdated', updatedContact);
        }
        if (
          (this.contactForSubmit.notifyContact &&
            this.notifyContactIsAllowed) ||
          this.haveEmailAddress
        ) {
          await this.getInvites();
        }
      } catch (e) {
        this.error = extractErrorMessages(e)
          .map((message) => this.$t(message))
          .join(' ');
      }
      this.loading = false;
    },
    async validateDeleteContact() {
      try {
        const validate = await this.validateDeleteDirectoryPerson(
          this.contactForSubmit.id
        );
        if (validate) {
          this.showDeleteContactModal();
        }
      } catch (e) {
        const errorMessages = extractErrorMessages(e);
        const extractedRoles = errorMessages[0]
          .replace('Person in use: ', '')
          .split(', ');
        this.contactInUseRoles = extractedRoles;
        this.showContactInUseModal();
      }
    },
    async deleteContact() {
      this.loading = true;
      try {
        const deleteSuccess = await this.deleteDirectoryPerson(
          this.contactForSubmit.id
        );
        if (deleteSuccess) {
          this.hideDeleteContactModal();
          this.close();
        } else {
          this.error = this.$t('components.contactForm.deleteError');
        }
      } catch (e) {
        this.error = extractErrorMessages(e)
          .map((message) => this.$t(message))
          .join(' ');
      }
      this.loading = false;
    },
    showContactInUseModal() {
      this.contactInUseModalIsVisible = true;
    },
    hideContactInUseModal() {
      this.contactInUseRoles = [];
      this.contactInUseModalIsVisible = false;
    },
    showDeleteContactModal() {
      this.deletecontactModalIsVisible = true;
    },
    hideDeleteContactModal() {
      this.deletecontactModalIsVisible = false;
    },
    showChangeRelationshipModal() {
      this.relationshipModalIsVisible = true;
    },
    hideChangeRelationshipModal() {
      this.relationshipModalIsVisible = false;
    },
    validateChangeRelationship(newRelationship) {
      if (
        !this.relationshipModalIsVisible &&
        !!this.modelContact?.id &&
        [RELATIONSHIP_TYPE.PARTNER, RELATIONSHIP_TYPE.CHILD].includes(
          this.contactCurrentRelationshipWithUser
        ) &&
        newRelationship !== this.contactCurrentRelationshipWithUser
      ) {
        this.newRelationship = newRelationship;
        this.showChangeRelationshipModal();
      } else {
        this.modelContact.relationshipWithUser = newRelationship;
      }
    },
    changeRelationship() {
      this.modelContact.relationshipWithUser = this.newRelationship;
      this.hideChangeRelationshipModal();
      this.newRelationship = null;
    },
    rollbackRelationship() {
      this.modelContact.relationshipWithUser =
        this.contactCurrentRelationshipWithUser;
      this.hideChangeRelationshipModal();
      this.newRelationship = null;
    },
  },
};
