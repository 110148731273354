
import { mapActions, mapGetters } from 'vuex';
import { queries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';

import SET_ATTORNEYS from '@/graphql/mutations/SetAttorneys';
import { RELATIONSHIP_TYPE, AU_STATE } from '@/utilities/constants';
import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalPrimaryDecisionMakers',
  mixins: [will],
  apollo: {
    attorneys: {
      ...queries.getAttorneys({ power: 'MEDICAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setAttorneys(data.poa);
      },
    },
  },
  data() {
    return {
      RELATIONSHIP_TYPE,
      attorneys: undefined,
      selectedContacts: [],
      attorneysAllowed: {
        act: 4,
        nsw: 4,
        nt: 4,
        qld: 3,
        sa: 3,
        tas: 4,
        vic: 1,
        wa: 2,
      },
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('user/contacts', ['userDetails', 'contacts']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
        numPrimaryAttorneysSelected: this.selectedContacts.length,
      });
    },
    isComplete() {
      return this.selectedContacts && this.selectedContacts.length > 0;
    },
    userState() {
      return this.userDetails.residentialAddress.region?.toLowerCase();
    },
    excludedContacts() {
      return this.attorneys
        ?.filter((attorney) => attorney.role === 'SECONDARY')
        ?.map((attorney) => attorney.directoryPerson.id);
    },
    isDobRequired() {
      return this.userState
        ? [AU_STATE.VIC, AU_STATE.SA].includes(this.userState.toUpperCase())
        : true;
    },
    isPhoneRequired() {
      return this.userState
        ? this.userState.toUpperCase() === AU_STATE.SA
        : true;
    },
  },
  watch: {
    attorneys(attorneys) {
      if (attorneys.length && !this.selectedContacts.length) {
        this.selectedContacts = attorneys
          .filter((attorney) => attorney.role === 'PRIMARY')
          .map((attorney) => attorney.directoryPerson.id);
      }
    },
  },
  methods: {
    ...mapActions('poa', ['setAttorneys']),
    async onSubmit() {
      try {
        await this.$apollo.mutate({
          mutation: SET_ATTORNEYS,
          variables: {
            directoryPersonIds: this.selectedContacts,
            power: 'MEDICAL',
            role: 'PRIMARY',
            poaId: this.poaId,
          },
        });
        const path = this.localePath(this.next);
        this.$router.push({ path });
      } catch (error) {
        this.$refs.form.setError(error);
      }
      await this.$apollo.queries.attorneys.refetch();
    },
  },
};
