
import { mapGetters, mapActions } from 'vuex';
import { will, purchasedProducts } from '@/mixins/apollo';

export default {
  name: 'AppDashboardWillGateway',
  mixins: [will, purchasedProducts],
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isWillTierTwoUpgradeModalVisible: false,
      isWillTierTwoDowngradeModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(['willStatus', 'isFreeWillWeek']),
    ...mapGetters('modules', ['modules']),
    ...mapGetters('user/contacts', ['userDetails']),
    ...mapGetters('progress', {
      willProgress: 'will',
      willFirstIncompleteSectionPath: 'willFirstIncompleteSectionPath',
    }),
    ...mapGetters('flag', ['hasActivePlanFlags', 'isPlanModuleFlagged']),
    ...mapGetters('orders', ['isProductPurchased']),
    ...mapGetters('user/cart', ['isInCart']),
    ...mapGetters('will-tiers', [
      'isWillTierTwoUpgradeAvailable',
      'isWillTierTwoDowngradeAvailable',
      'hasPurchasedWillTierTwo',
      'hasPurchasedWillProduct',
      'isLoading',
    ]),
    ...mapGetters('will-tiers', ['isRecommendationPageAvailable']),
    items() {
      return this.modules.map(
        ({ id, key, flagKey, displayName, path: cta }) => ({
          title: this.$t(displayName),
          status: this.getModuleStatus(key, flagKey),
          cta: this.getItemCta(this.willStatus, cta, id),
          id,
        })
      );
    },
    willStatusTooltipText() {
      switch (this.willStatus) {
        case 'AWAITING_APPROVAL':
          return this.$t('components.statusText.awaitingApproval');
        case 'FLAGGED':
          return this.$t('components.statusText.flagged');
        case 'APPROVED':
          return this.$t('components.statusText.approved');
        default:
          return null;
      }
    },
    ctaText() {
      if (this.hasPurchasedWillProduct && this.willStatus === 'IN_PROGRESS') {
        return this.$t('components.willGateway.inProgress.cta');
      }
      if (this.willStatus === 'APPROVED') {
        return this.$t('components.willGateway.approved.cta');
      }
      if (this.willStatus === 'AWAITING_APPROVAL') {
        return this.$t('components.willGateway.awaitingApproval.cta');
      }
      if (this.userDetails.lastName) {
        return 'Complete my Will';
      }
      return this.$t('components.willGateway.notStarted.cta');
    },
    incompleteWillPath() {
      return this.$ff.combinedFreeWillCheckoutSubmission()
        ? this.localePath(this.willFirstIncompleteSectionPath)
        : this.localePath('/will');
    },
    primaryCtaList() {
      const ctaList = [];
      switch (true) {
        case this.willStatus === 'IN_PROGRESS' && this.willProgress.complete:
          ctaList.push({
            text: this.$t('components.willGateway.submitYourWill'),
            link: '/will',
            icon: 'pi-arrow-right',
          });
          break;
        case this.willStatus === 'NOT_STARTED':
          ctaList.push({
            text: this.$t('components.willGateway.startMyWill'),
            link: '/will',
            icon: 'pi-arrow-right',
          });
          break;
        case this.willStatus === 'IN_PROGRESS':
          ctaList.push({
            text: this.$t('components.willGateway.completeMyWill'),
            link: this.incompleteWillPath,
            icon: 'pi-arrow-right',
          });
          break;
        case this.willStatus === 'AWAITING_APPROVAL':
          ctaList.push({
            text: this.$t('components.willGateway.downloadMyWill'),
            link: '/will',
            icon: 'pi-download',
            disabled: true,
          });
          break;
        case this.willStatus === 'FLAGGED':
          ctaList.push({
            text: this.$t('components.willGateway.editMyWill'),
            link: '/will',
            icon: 'pi-arrow-right',
          });
          break;
        case this.willStatus === 'APPROVED':
          ctaList.push({
            text: this.$t('components.willGateway.downloadMyWill'),
            link: '/will',
            icon: 'pi-download',
          });
          ctaList.push({
            text: this.$t('components.willGateway.updateMyWill'),
            link: '/will',
            icon: 'pi-pencil',
            class: 'p-button-outlined',
          });
          break;
        default:
          ctaList.push({
            link: '/will',
            text: this.$t('components.willGateway.editMyWill'),
          });
          break;
      }

      if (this.isWillTierTwoUpgradeAvailable) {
        ctaList.push({
          id: 'will-gateway__will-tiers--upgrade',
          text: this.$t('components.willGateway.upgradeToWillTierTwo'),
          emit: 'upgradeToWillTierTwo',
          icon: ' ',
          class: 'p-button-outlined',
        });
      } else if (this.isWillTierTwoDowngradeAvailable) {
        ctaList.push({
          id: 'will-gateway__will-tiers--downgrade',
          text: this.$t('components.willGateway.downgradeFromWillTierTwo'),
          emit: 'downgradeFromWillTierTwo',
          icon: ' ',
          class: 'p-button-outlined',
        });
      }

      return ctaList;
    },
    itemsDescription() {
      const totalModules = Object.keys(this.willProgress.modules).length;
      const completedModules = Object.keys(this.willProgress.modules).filter(
        (module) => {
          return this.willProgress.modules[module].decimal === 1;
        }
      ).length;
      return `${completedModules} ${this.$t(
        'components.willGateway.outOf'
      )} ${totalModules} ${this.$t('components.willGateway.areComplete')}`;
    },
    showFlaggedAlert() {
      return (
        this.hasActivePlanFlags &&
        ['FLAGGED', 'IN_PROGRESS'].includes(this.willStatus)
      );
    },
    isWillTierTagVisible() {
      return (
        this.isProductPurchasedOrInCart('WILL') ||
        this.isProductPurchasedOrInCart('WILL_TIER_TWO')
      );
    },
    isWillTierTwoTag() {
      return this.isProductPurchasedOrInCart('WILL_TIER_TWO');
    },
    reviewingMessage() {
      switch (true) {
        case this.isFreeWillWeek:
          return this.$t(
            'components.willGateway.description.fwwAwaitingApproval'
          );
        case this.hasPurchasedWillTierTwo:
          return this.$t(
            'components.willGateway.description.willTierTwoAwaitingApproval'
          );
        default:
          return this.$t('components.willGateway.description.awaitingApproval');
      }
    },
  },
  mounted() {
    this.loadToolAndVaultItems();
  },
  methods: {
    ...mapActions('user-onboarding', ['loadToolAndVaultItems']),
    getModuleStatus(moduleKey, flagKey) {
      if (
        this.isPlanModuleFlagged(flagKey) &&
        ['FLAGGED', 'IN_PROGRESS'].includes(this.willStatus)
      ) {
        return 'flagged';
      }
      if (this.willStatus === 'APPROVED') {
        return 'approved';
      }
      if (this.willStatus === 'AWAITING_APPROVAL') {
        return 'awaiting-approval';
      }
      if (this.willStatus === 'NOT_STARTED') {
        return 'not-started';
      }
      if (this.willProgress.modules[moduleKey].decimal === 1) {
        return 'completed';
      } else if (this.willProgress.modules[moduleKey].decimal > 0) {
        return 'in-progress';
      }
      return 'not-started';
    },
    isProductPurchasedOrInCart(product) {
      return this.isProductPurchased(product) || this.isInCart(product);
    },
    getItemCta(willStatus, cta, id) {
      switch (true) {
        case willStatus !== 'IN_PROGRESS':
          return '/will';
        case id === 'review' && this.isRecommendationPageAvailable:
          return '/will/review/recommendation';
        default:
          return cta;
      }
    },
  },
};
