
import { mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateIndex',
  mixins: [will],
  layout: 'ruach',
  computed: {
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters('user/contacts', ['userDetails']),
  },
};
