
import { mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillExecutorsIndex',
  mixins: [will],
  data: () => ({
    executorTodoListIsVisible: false,
  }),
  computed: {
    ...mapGetters('user/contacts', ['userIsAustralian']),
  },
  methods: {
    toggleExecutorToDoList() {
      this.executorTodoListIsVisible = !this.executorTodoListIsVisible;
    },
    async nextStep() {
      const redirectPath = this.localePath(
        this.userIsAustralian
          ? '/will/executors/options'
          : '/will/executors/primary'
      );
      if (!this.userIsAustralian) {
        this.willMeta.executors_option = 'friendsFamily';
        await this.updateWillMeta();
      }
      this.$router.push({ path: redirectPath });
    },
  },
};
