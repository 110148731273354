
import { mapGetters, mapActions } from 'vuex';
import { user } from '@/mixins/apollo';
import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';

export default {
  name: 'AppAccountSendGifts',
  mixins: [user],
  apollo: {
    purchasedGifts: {
      query: GET_PURCHASED_PRODUCTS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          userId: this.userId,
        };
      },
      update(data) {
        const products = data.getPurchasedProducts.products || [];
        return {
          WILL: products.filter(
            (product) =>
              product.includes('WILL_GIFT') || product.includes('PARTNER_WILL')
          ).length,
          POA: products.filter(
            (product) =>
              product.includes('POA_GIFT') || product.includes('PARTNER_POA')
          ).length,
        };
      },
      skip() {
        return !this.userId;
      },
    },
  },
  data() {
    return {
      formData: {
        email: null,
        WILL: false,
        POA: false,
      },
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('user/contacts', ['contacts', 'userIsAustralian']),
    ...mapGetters('invites', ['invites']),
    hasPurchasedGifts() {
      return Object.values(this.purchasedGifts).some((value) => value > 0);
    },
    sentGifts() {
      const sentInvites = this.invites
        ?.filter(({ type }) => {
          return type === 'PAID';
        })
        .flatMap(({ discountProductCodes }) => discountProductCodes);
      const sentGifts = {};
      sentInvites.forEach((product1) => {
        sentGifts[product1] = sentInvites.filter(
          (product2) => product1 === product2
        ).length;
      });
      return sentGifts;
    },
    excludedGiftContacts() {
      const inviteMap = this.invites.reduce((acc, cur) => {
        if (cur.type === 'PAID') {
          acc[cur.email] = acc[cur.email]
            ? [...acc[cur.email], ...cur.discountProductCodes]
            : cur.discountProductCodes;
        }
        return acc;
      }, {});

      const excludeList = new Set();
      for (const contact of this.contacts) {
        let isInvited = false;
        const contactEmail = contact.defaultEmailAddress;
        // Check if the user has a gift with any of the selected products already
        if (inviteMap[contactEmail]) {
          isInvited = !!this.selectedProducts.find((sProduct) =>
            inviteMap[contactEmail].includes(sProduct)
          );
        }
        if (isInvited) {
          excludeList.add(contact.id);
        }
      }
      return [...excludeList];
    },
    selectedProducts() {
      const gifts = [];
      if (this.formData.WILL) {
        gifts.push('WILL');
      }
      if (this.formData.POA) {
        gifts.push('POA');
      }
      return gifts;
    },
    remainingGifts() {
      if (!this.purchasedGifts || !this.invites) {
        return null;
      }
      const remainingGifts = {};

      Object.keys(this.purchasedGifts).forEach((gift) => {
        const sentGifts = this.sentGifts[gift] || 0;
        remainingGifts[gift] = this.purchasedGifts[gift] - sentGifts;
      });
      return remainingGifts;
    },
    remainingWillGifts() {
      return this.remainingGifts.WILL || 0;
    },
    remainingPoaGifts() {
      return this.remainingGifts.POA || 0;
    },
    hasRemainingGifts() {
      return +this.remainingGifts.WILL + +this.remainingGifts.POA > 0;
    },
  },
  methods: {
    ...mapActions('user/contacts', ['getDirectoryPersonsByOwnerId']),
    async clearFieldsAndRefreshContacts() {
      this.formData.WILL = false;
      this.formData.POA = false;

      await this.getDirectoryPersonsByOwnerId({ ownerId: this.userId });
    },
  },
};
