
import { mapGetters, mapActions } from 'vuex';
import { metaArrayToObject } from '@/utilities';
import { isSameCharity } from '@/utilities/charity';
import { beneficiaries, charities, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillEstateIncludeCharity',
  mixins: [beneficiaries, charities, user, will],
  data() {
    return {
      selectedContacts: [],
      selectedSuggestedCharities: [],
      selectedCauseIds: [],
    };
  },
  computed: {
    ...mapGetters('user/contacts', ['contacts']),
    ...mapGetters('application/causes', ['causes']),
    ...mapGetters('application/affiliate', ['slug']),
    isComplete() {
      const madeSelection = this.willMeta.charity_in_estate !== undefined;
      const includeCharity = this.willMeta.charity_in_estate;
      const hasSelectedSuggestedBeneficiaries = !!(
        this.showSuggestedCharities && !!this.selectedSuggestedCharities.length
      );
      const hasSelectedCharities = this.selectedContacts.some((id) =>
        this.charities.find((charity) => charity.id === id)
      );

      const hasSelectedCauses = this.selectedContacts.some((id) =>
        this.displayedCauses.find((cause) => cause.id === id)
      );

      return (
        madeSelection &&
        (!includeCharity ||
          (includeCharity &&
            (hasSelectedCharities ||
              hasSelectedCauses ||
              hasSelectedSuggestedBeneficiaries)))
      );
    },
    showSuggestedCharities() {
      return !this.charities.length;
    },
    displayedCauses() {
      const causeKeys = {};
      this.causes.forEach((cause) => {
        if (
          !this.willMeta.referral_charity ||
          this.selectedCauseIds.includes(cause.id)
        ) {
          causeKeys[cause.slug] = cause;
        }
      });

      this.beneficiaries.forEach((beneficiary) => {
        if (beneficiary.cause) {
          causeKeys[beneficiary.cause.slug] = beneficiary.cause;
        }
      });
      return Object.values(causeKeys);
    },
  },
  watch: {
    beneficiaries() {
      if (
        this.willMeta.estate_split === 'custom' &&
        this.beneficiaries.length &&
        !this.selectedContacts.length
      ) {
        const contacts = this.contacts
          .filter((contact) => {
            return this.beneficiaries.find(
              (beneficiary) => beneficiary?.directoryPerson?.id === contact.id
            );
          })
          .map((person) => person.id);

        const charities = this.charities
          .filter((charity) => {
            return this.beneficiaries.find(
              (beneficiary) =>
                beneficiary.charity && beneficiary.charity.id === charity.id
            );
          })
          .map((charity) => charity.id);

        const causeIds = this.beneficiaries.reduce((ids, beneficiary) => {
          if (beneficiary.cause) {
            ids.push(beneficiary.cause.id);
          }
          return ids;
        }, []);

        this.selectedContacts = contacts.concat(charities).concat(causeIds);
      }
    },
  },
  mounted() {
    this.willMeta.estate_backup_index = 0;
    this.updateWillMeta();
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    updateSelectedCauseIds(value) {
      this.selectedCauseIds = value.slice();
      this.selectedContacts = value;
    },
    async done() {
      if (this.willMeta.charity_in_estate) {
        this.willMeta.estate_split = 'custom';
        await this.updateWillMeta();

        if (this.selectedSuggestedCharities.length) {
          await Promise.all(
            this.selectedSuggestedCharities.map((charity) => {
              return this.addPartnerCharity(charity);
            })
          );
          await this.refetchCharities();
          this.charities.forEach((charity) => {
            const charityMeta = metaArrayToObject(charity.meta);
            const exists = this.selectedSuggestedCharities.find(
              (selectedSuggestedCharity) => {
                return isSameCharity(selectedSuggestedCharity, charityMeta);
              }
            );
            if (exists) {
              this.selectedContacts.push(charity.id);
            }
          });
        }

        for (const charity of this.charities) {
          const beneficiary = this.getBeneficiaryByCharity(charity);
          const isSelected = this.selectedContacts.includes(charity.id);

          if (isSelected) {
            if (!beneficiary) {
              await this.addBeneficiary(charity, null);
            }
          } else if (beneficiary) {
            await this.removeBeneficiary(beneficiary);
          }
        }

        for (const cause of this.displayedCauses) {
          const beneficiary = this.getBeneficiaryByCause(cause);
          const isSelected = this.selectedContacts.includes(cause.id);

          if (isSelected) {
            if (!beneficiary) {
              await this.addBeneficiary(cause, null);
            }
          } else if (beneficiary) {
            await this.removeBeneficiary(beneficiary);
          }
        }
      }

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift:
          this.willMeta.charity_in_gifts || this.willMeta.charity_in_estate,
      });

      this.sendBeneficiariesAddedEvent();

      const currentPrimaryTotal = this.beneficiaries.reduce(
        (total, beneficiary) => {
          return total + Number(beneficiary.distribution);
        },
        0
      );
      const mustRedistribute =
        this.willMeta.estate_split === 'custom' || currentPrimaryTotal !== 100;

      if (mustRedistribute) {
        await this.$router.push(this.localePath('/will/estate/distribution'));
      } else {
        await this.updateWillIsPrimaryEstateSplitEvenly(false);
        await this.$router.push(
          this.localePath(
            `/will/estate/backup-beneficiaries/${this.backupQueue[0].id}`
          )
        );
      }
    },
  },
};
