
import { mapActions, mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalPrimaryDecisions',
  mixins: [user, will],
  apollo: {
    poaMedicalMeta: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
  },
  data() {
    return {
      poaMedicalMeta: {
        attorney_decisions: undefined,
        attorney_decisions_other: undefined,
        has_substitute: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('user/contacts', ['userState']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
        poaMedicalMeta: this.poaMedicalMeta,
        addressState: this.userState,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
        addressState: this.userState,
      });
    },
    isComplete() {
      const completedMainQuestion =
        !this.askAttorneyDecisions || this.poaMedicalMeta.attorney_decisions;
      const completedOtherQuestion =
        !this.askAttorneyDecisionsOther ||
        this.poaMedicalMeta.attorney_decisions_other;
      return completedMainQuestion && completedOtherQuestion;
    },
    askAttorneyDecisions() {
      return ['nsw', 'qld', 'act', 'sa', 'nt'].includes(this.userState);
    },
    askAttorneyDecisionsOther() {
      return (
        ['tas', 'wa'].includes(this.userState) ||
        (['nsw', 'nt', 'tas'].includes(this.userState) &&
          this.poaMedicalMeta.attorney_decisions === 'jointly')
      );
    },
  },
  methods: {
    ...mapActions('poa', ['setData']),
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'attorney_decisions',
            value: this.poaMedicalMeta.attorney_decisions,
            category: 'MEDICAL',
          },
          {
            key: 'attorney_decisions_other',
            value: this.poaMedicalMeta.attorney_decisions_other,
            category: 'MEDICAL',
          },
        ])
      );

      const path = this.localePath(this.next);
      this.$router.push({ path });
    },
  },
};
