
import { mapGetters } from 'vuex';
import cookies from 'js-cookie';
import { showChat } from '@/modules/chat';
import { charityBackgrounds } from '@/modules/charityData';
import { makePartnerLandingUrl } from '@/utilities';

export default {
  name: 'AppGetStartedDefault',
  data() {
    return {
      background: null,
      screen: 'start',
    };
  },
  computed: {
    ...mapGetters('application/affiliate', [
      'branding',
      'slug',
      'getStartedBranding',
    ]),
    ...mapGetters(['isFreeWillWeek']),
    hasAffiliateLogo() {
      return (
        this.getStartedBranding.affiliateLogoVisible && !!this.branding.logo
      );
    },
    contentList() {
      return [
        {
          icon: 'chat',
          key: 'branding-content-chat',
          headline: this.getStartedBranding.titleOne
            ? this.getStartedBranding.titleOne
            : this.$t('pages.getStarted.chat.headline'),
          copy: this.getStartedBranding.descriptionOne
            ? this.getStartedBranding.descriptionOne
            : this.$t('pages.getStarted.chat.copy'),
        },
        {
          icon: 'glasses',
          key: 'branding-content-glasses',
          headline: this.getStartedBranding.titleTwo
            ? this.getStartedBranding.titleTwo
            : this.$t('pages.getStarted.glasses.headline'),
          copy: this.getStartedBranding.descriptionTwo
            ? this.getStartedBranding.descriptionTwo
            : this.$t('pages.getStarted.glasses.copy'),
        },
        ...(this.getStartedBranding.titleThree &&
        this.getStartedBranding.descriptionThree
          ? [
              {
                key: 'branding-content-card',
                icon: 'card',
                headline: this.getStartedBranding.titleThree,
                copy: this.getStartedBranding.descriptionThree,
              },
            ]
          : []),
      ];
    },
  },
  mounted() {
    this.setBackground();
  },
  methods: {
    showChat,
    back() {
      this.screen = 'start';
    },
    callback() {
      this.screen = 'callback';
    },
    setBackground() {
      if (this.branding.landingImage) {
        this.background = makePartnerLandingUrl(this.branding.landingImage);
        return;
      }

      const referral = cookies.get('referral_charity')
        ? cookies.get('referral_charity').toLowerCase()
        : null;
      const charityBackground = charityBackgrounds[referral];

      if (charityBackground) {
        this.background = charityBackground;
      } else {
        this.background = '/img/pilot-kid.png';
      }
    },
  },
};
