
import { mapGetters } from 'vuex';
import { makePartnerLogoUrl } from '@/utilities';

export default {
  name: 'AppAffiliateLogo',
  computed: {
    ...mapGetters('application/affiliate', ['branding']),
    logo() {
      return makePartnerLogoUrl(this.branding.logo);
    },
  },
};
