
import { mapGetters, mapActions } from 'vuex';
import { gifts, user, will } from '@/mixins/apollo';
import { giftsToGiftsAnalyticsParams } from '@/utilities/charity';
import { VAULT_ITEM_KEY, VAULT_ITEM_TYPE } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillGiftsIndex',
  mixins: [gifts, user, will],
  data() {
    return {
      VAULT_ITEM_KEY,
      VAULT_ITEM_TYPE,
      isHelpRequired: false,
    };
  },
  computed: {
    ...mapGetters('application/ui', [
      'giftWizardIsOpen',
      'shouldShowCharityConsentPage',
    ]),
    ...mapGetters('tool', ['willHelpAssessmentTool']),
    ...mapGetters('will-tiers', ['isHelpAvailable']),
    isComplete() {
      return !!(this.willMeta.has_gifts === false || this.gifts.length);
    },
    submitLabel() {
      const noCharity = !this.gifts.find((g) => g.charity !== null);
      if (noCharity || this.willMeta.has_gifts === false) {
        return this.$t('forms.labels.next');
      }
      return this.$t('forms.labels.continueToAssets');
    },
  },
  async mounted() {
    await this.getGiftsData();
  },
  methods: {
    ...mapActions('gift', ['addGift', 'editGift']),
    ...mapActions('will', ['getGiftsData']),
    ...mapActions('vault-item', ['saveHelpRequiredVaultItem']),
    getGiftDescription(gift, charityMeta) {
      return (
        gift?.recipientDirectoryPerson?.fullName ??
        charityMeta.displayName ??
        charityMeta.name
      );
    },
    async done() {
      const promises = [];
      promises.push(
        this.isHelpAvailable
          ? this.saveHelpRequiredVaultItem({
              tool: this.willHelpAssessmentTool,
              vaultItemKey: VAULT_ITEM_KEY.DECISION_INSTRUCTION_DEATH,
              vaultItemType: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY_GIFT,
              helpRequired: this.isHelpRequired,
            })
          : Promise.resolve()
      );

      if (this.willMeta.has_gifts === false && this.gifts.length) {
        promises.push(this.removeAllGifts());
      }

      await Promise.all(promises);
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_module',
        props: {
          will_id: this.willId,
          module_name: 'Gifts',
        },
      });

      const noCharity = !this.gifts.find((g) => g.charity !== null);

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift: !noCharity,
      });
      const analyticsGiftsParams = giftsToGiftsAnalyticsParams(this.gifts);
      const flatAnalyticsGiftsParams = {};
      if (analyticsGiftsParams.recipient_type === 'charity') {
        flatAnalyticsGiftsParams[
          `charity_${analyticsGiftsParams.charity_name}_type_${analyticsGiftsParams.giftType}_value`
        ] = analyticsGiftsParams.gift_value;
      } else {
        flatAnalyticsGiftsParams[
          `individual_type_${analyticsGiftsParams.giftType}_value`
        ] = analyticsGiftsParams.gift_value;
      }

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'add_gifts',
        props: {
          gifts: analyticsGiftsParams,
          ...flatAnalyticsGiftsParams,
        },
      });

      if (noCharity) {
        this.$router.push({
          path: this.localePath('/will/gifts/include-charity'),
        });
      } else {
        this.willMeta.charity_in_gifts = 'true';
        await this.updateWillMeta();
        await this.refetchWill();
        const path = this.localePath(
          this.shouldShowCharityConsentPage
            ? '/will/charity-consent?next-module=assets'
            : '/will/assets'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
