import { render, staticRenderFns } from "./SendInvite.vue?vue&type=template&id=a8fbb804"
import script from "./SendInvite.vue?vue&type=script&lang=js"
export * from "./SendInvite.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppContactSelector: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ContactSelector.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppFormWrapper: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/FormWrapper.vue').default})
