
import { mapGetters } from 'vuex';

export default {
  name: 'AppSnowflakesApp',
  computed: {
    ...mapGetters('application/affiliate', ['branding', 'slug', 'appBranding']),
    ...mapGetters('ruach', [
      'logoVariant',
      'navigationVariant',
      'activeProduct',
    ]),
    ...mapGetters('user/contacts', ['userDetails']),
    ...mapGetters('progress', {
      willProgress: 'will',
      poaProgress: 'poa',
    }),
    ...mapGetters('application/banners', [
      'showOver60Banner',
      'showBlackFridayBanner',
    ]),
    ...mapGetters('user/cart', ['isCartEmpty']),
    hasAffiliateLogo() {
      return this.appBranding.affiliateLogoVisible && !!this.branding.logo;
    },
    showNavigation() {
      return (
        !!this.userDetails.residentialAddress?.country &&
        this.navigationVariant !== 'none'
      );
    },
    hasActiveProductWithProgress() {
      return !!this.activeProductProgress[this.activeProduct];
    },
    activeProductProgress() {
      return {
        will: this.willProgress,
        poa: this.poaProgress,
      };
    },
  },
};
