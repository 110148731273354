import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=59ccd9c9"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppSimpleTable: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/SimpleTable.vue').default,AppComments: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/Comments.vue').default,AppModuleSummary: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/ModuleSummary.vue').default,AppCard: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/Card.vue').default,AppStatusChip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/StatusChip.vue').default,AppCardSection: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/CardSection.vue').default,AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppBaseButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/BaseButton.vue').default,AppActionLink: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/ActionLink.vue').default,AppPartnerModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/PartnerModal.vue').default,AppTransferWillModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/TransferWillModal.vue').default,AppFixedBox: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/FixedBox.vue').default})
