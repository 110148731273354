import { render, staticRenderFns } from "./PowerOfAttorneyGateway.vue?vue&type=template&id=b7c60f24"
import script from "./PowerOfAttorneyGateway.vue?vue&type=script&lang=js"
export * from "./PowerOfAttorneyGateway.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Image.vue').default,AppProductGateway: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/ProductGateway.vue').default})
