import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9201f3a4"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=9201f3a4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppDashboardProgressSummary: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/dashboard/ProgressSummary.vue').default,AppRecommendedAction: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/RecommendedAction.vue').default,AppDashboardWillGateway: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/dashboard/WillGateway.vue').default,AppDashboardPowerOfAttorneyGateway: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/dashboard/PowerOfAttorneyGateway.vue').default,AppDashboardVaultGateway: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/dashboard/VaultGateway.vue').default})
