
import { contactNameOrDefault } from '@/utilities';

export default {
  name: 'DeleteContactModal',
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contactName() {
      return contactNameOrDefault(
        this.contact,
        this.$t('components.deleteContact.thisContact')
      );
    },
  },
};
