
import { mapGetters, mapActions } from 'vuex';
import { purchasedProducts } from '@/mixins/apollo';

export default {
  name: 'AppDashboardProgressSummary',
  mixins: [purchasedProducts],
  computed: {
    ...mapGetters('modules', ['modules']),
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters([
      'willEnabled',
      'powerOfAttorneyEnabled',
      'endOfLifeEnabled',
    ]),
    ...mapGetters('user/end-of-life', ['endOfLifeCategoryItemCounts']),
    ...mapGetters('progress', { willProgress: 'will', poaProgress: 'poa' }),
    ...mapGetters('user/contacts', ['userIsAustralian']),
    vaultProgress() {
      const totalCategories = Object.keys(
        this.endOfLifeCategoryItemCounts
      ).length;
      if (!totalCategories) return 1;
      const categoriesHaveItems = Object.values(
        this.endOfLifeCategoryItemCounts
      ).reduce((total, count) => total + count, 0);
      return Math.min(categoriesHaveItems / totalCategories, 0.95);
    },
    endOfLifePlaningCta() {
      return this.endOfLifePlaningProgress > 0
        ? '/end-of-life'
        : '/onboarding/end-of-life';
    },
    poaCta() {
      return this.userIsAustralian ? '/poa' : '/onboarding/poa';
    },
    styles() {
      return {
        '--will-color':
          this.willProgress.decimal > 0 ? 'var(--turquoise)' : 'var(--rock)',
        '--poa-color':
          this.poaProgress.decimal > 0 ? 'var(--turquoise)' : 'var(--rock)',
        '--vault-color':
          this.vaultProgress > 0 ? 'var(--turquoise)' : 'var(--rock)',
      };
    },
  },
  async created() {
    await this.getEndOfLifeCategoryItemCounts();
  },
  methods: {
    ...mapActions('user/end-of-life', ['getEndOfLifeCategoryItemCounts']),
  },
};
