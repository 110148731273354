
import { mapGetters } from 'vuex';
import { executors, user, will } from '@/mixins/apollo';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillExecutorsBackup',
  mixins: [executors, user, will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      selectedContacts: [],
    };
  },
  computed: {
    ...mapGetters('user/contacts', ['contacts']),
    prevStep() {
      switch (this.willMeta.executors_option) {
        case 'professional':
          return '/will/executors/professional-terms';
        case 'friendsFamily':
        case 'friendsFamilyAndProfessional':
          return '/will/executors/primary';
        default:
          return '/will/executors/options';
      }
    },
    isComplete() {
      return (
        this.willMeta.has_backup_executor === false ||
        !!this.selectedContacts.length
      );
    },
    excludedContacts() {
      return this.primaryExecutors.map((executor) => {
        return executor.directoryPerson.id;
      });
    },
  },
  watch: {
    backupExecutors() {
      this.updateSelectedBackupExecutor();
    },
  },
  mounted() {
    this.getExecutorsData();
  },
  created() {
    this.updateSelectedBackupExecutor();
  },
  methods: {
    updateSelectedBackupExecutor() {
      this.selectedContacts = this.contacts
        .filter((contact) => {
          return this.backupExecutors.find(
            (executor) => contact.id === executor.directoryPerson.id
          );
        })
        .map((contact) => contact.id);
    },
    async done() {
      try {
        await Promise.all(
          this.contacts.map((contact) => {
            const isBackupExecutor = this.isBackupExecutor(contact);
            const isSelected = this.selectedContacts.includes(contact.id);
            if (isSelected) {
              if (!isBackupExecutor) {
                return this.addExecutor(contact.id, 'backup');
              }
            }
            if (
              (!isSelected && isBackupExecutor) ||
              (isSelected && !this.willMeta.has_backup_executor)
            ) {
              return this.removeExecutor(contact);
            }
            return Promise.resolve();
          })
        );

        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'complete_module',
          props: {
            will_id: this.willId,
            module_name: 'Executors',
          },
        });
        this.$router.push({
          path: this.localePath('/will/funeral'),
        });
      } catch (error) {
        console.error(error);
        this.$refs.form.setGenericError('assigningContacts');
      }
    },
  },
};
