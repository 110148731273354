
import { mapGetters, mapActions } from 'vuex';
import { will, subscription } from '@/mixins/apollo';
import { securityLink, privacyLink } from '@/utilities/constants';

export default {
  name: 'AppSnowflakesNavigation',
  mixins: [will, subscription],
  props: {
    variant: {
      default: 'full',
      type: String,
    },
  },
  data() {
    return {
      mobileNavigationIsOpen: false,
      dashboardMenuIsCollapsed: false,
    };
  },
  computed: {
    ...mapGetters('application/affiliate', ['features', 'slug']),
    ...mapGetters([
      'verified',
      'willStatus',
      'willEnabled',
      'powerOfAttorneyEnabled',
    ]),
    ...mapGetters('user/contacts', ['userDetails']),
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('user/end-of-life', ['endOfLifeCategoryItemCounts']),
    ...mapGetters('application/end-of-life', ['endOfLifeCategoryTree']),
    hasEndOfLifeItems() {
      return this.endOfLifeCategoryItemCounts &&
        Object.keys(this.endOfLifeCategoryItemCounts)
        ? Object.keys(this.endOfLifeCategoryItemCounts).some(
            (category) => this.endOfLifeCategoryItemCounts[category] > 0
          )
        : false;
    },
    modifiers() {
      return {
        '--mobile-menu-open': this.mobileNavigationIsOpen,
      };
    },
    endOfLifeDropdownIsOpen() {
      return this.$route.path.includes('/end-of-life');
    },
    dashboardDropdownIsOpen() {
      return (
        this.localePath(`/${this.slug}`) === this.$route.path ||
        /\/(?:poa|end-of-life|will)/.test(this.$route.path)
      );
    },
    securityLink() {
      return securityLink[this.$i18n.locale];
    },
    privacyLink() {
      return privacyLink[this.$i18n.locale];
    },
  },
  watch: {
    mobileNavigationIsOpen(mobileNavigationIsOpen) {
      document.body.classList.toggle(
        '--mobile-navigation-is-open',
        mobileNavigationIsOpen
      );
    },
  },
  async created() {
    await Promise.all([
      this.getEndOfLifeCategories(),
      this.getEndOfLifeCategoryItemCounts(),
    ]);
  },
  methods: {
    ...mapActions('user/end-of-life', ['getEndOfLifeCategoryItemCounts']),
    ...mapActions('application/end-of-life', ['getEndOfLifeCategories']),
    toggleMobileMenuIfOpen() {
      if (this.mobileNavigationIsOpen) {
        this.toggleMobileMenu();
      }
    },
    toggleMobileMenu() {
      this.mobileNavigationIsOpen = !this.mobileNavigationIsOpen;
    },
  },
};
