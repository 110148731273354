import { render, staticRenderFns } from "./UserModal.vue?vue&type=template&id=a3a6e036"
import script from "./UserModal.vue?vue&type=script&lang=js"
export * from "./UserModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppCardRow: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/CardRow.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Modal.vue').default})
