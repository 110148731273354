
import { mapGetters, mapActions } from 'vuex';
import { formatPrice } from '@/utilities';

export default {
  name: 'AppCartItems',
  props: {
    alternateEmptyText: {
      default: undefined,
      type: String,
    },
    hideButton: {
      default: false,
      type: Boolean,
    },
    hideItemAction: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('user/cart', [
      'cartItems',
      'cartBasePrice',
      'cartFinalPrice',
      'cartItemFinalPrice',
      'isInCart',
      'cartItemPrices',
      'showSubscriptionAutoRenewalInCheckout',
    ]),
    ...mapGetters('orders', ['isProcessingOrder']),
    ...mapGetters('user/contacts', ['userIsAustralian']),
    ...mapGetters('progress', { willProgress: 'will' }),
    showCart() {
      return (
        this.cartItems.length ||
        this.showSubscriptionAutoRenewalInCheckout ||
        this.isProcessingOrder
      );
    },
    basePriceIsVisible() {
      return this.basePrice !== this.finalPrice;
    },
    basePrice() {
      return formatPrice(this.cartBasePrice, false);
    },
    finalPrice() {
      return formatPrice(this.cartFinalPrice, false);
    },
    canSubmitAndCheckout() {
      return (
        this.$ff.combinedFreeWillCheckoutSubmission() &&
        this.isInCart('WILL') &&
        this.cartItemPrices.find(
          (cartItemWithPrice) => cartItemWithPrice.product === 'WILL'
        ).finalPrice === 0 &&
        this.willProgress.complete
      );
    },
    subscriptionInCart() {
      return this.isInCart('SUBSCRIPTION');
    },
  },
  methods: {
    ...mapActions('user/cart', [
      'processFreeItems',
      'setShowSubscriptionAutoRenewalInCheckout',
    ]),
    ...mapActions('orders', ['setIsProcessingOrder']),
    async submitAndCheckout() {
      this.setIsProcessingOrder(true);
      try {
        await this.processFreeItems({
          trigger: 'Cart Submit',
          safelyFailSubmission: true,
        });
      } catch (err) {
        this.setIsProcessingOrder(false);
        throw err;
      }

      this.setShowSubscriptionAutoRenewalInCheckout(true);
      await this.$router.push({
        path: this.localePath('/checkout/cart'),
      });
    },
    async toCheckout() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'cart_checkout',
      });

      const items = this.cartItems.map((item) => {
        return {
          item_id: item.product.code,
          price: this.cartItemFinalPrice(item.product.code) / 100,
          quantity: 1,
        };
      });
      const flatItems = items.reduce((acc, item) => {
        acc[`${item.item_id.toLowerCase()}_price`] = item.price;
        return acc;
      }, {});

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'begin_checkout',
        props: {
          checkout_trigger: 'Cart',
          currency: this.$t('texts.words.currency'),
          value: this.cartFinalPrice / 100,
          items,
          ...flatItems,
        },
      });
      if (this.$ff.combinedFreeWillCheckoutSubmission()) {
        this.$toast.addDefault({
          severity: 'warn',
          title: 'toasts.submit.willNotYetSubmitted',
          message: 'toasts.submit.completeWillForReview',
          isTranslation: true,
        });
      }
      await this.$router.push({
        path: this.localePath(`/checkout/cart`),
      });
      this.$emit('closeCartPanel');
    },
  },
};
