
import { makePartnerLogoUrl, makeCauseLogoUrl } from '@/utilities';

export default {
  name: 'AppCause',
  props: {
    cause: {
      type: Object,
      default: null,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['select-option-change'],
  data: function () {
    return {
      showModal: false,
    };
  },
  computed: {
    selectCauseInModal: {
      get() {
        return this.selected;
      },
      set() {
        this.$emit('select-option-change');
      },
    },
  },
  methods: {
    makePartnerLogoUrl,
    makeCauseLogoUrl,
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
  },
};
