
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PagesAffiliatePoaAboutYourselfIndex',
  computed: {
    ...mapGetters('user/contacts', ['userDetails']),
    ...mapFields('user/contacts', [
      'user.firstName',
      'user.lastName',
      'user.middleName',
    ]),
    isComplete() {
      return !!(this.firstName && this.lastName);
    },
  },
  methods: {
    ...mapActions('user/contacts', ['updateDirectoryPerson']),
    async updateUserName() {
      await this.updateDirectoryPerson({
        id: this.userDetails.id,
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
      });
      this.$nuxt.$emit('sendTrackingAttributes', {
        name: this.userDetails.fullName,
      });

      this.$router.push({
        path: this.localePath('/poa/about-yourself/address'),
      });
    },
  },
};
