
import { mapActions } from 'vuex';
import { user, executors } from '@/mixins/apollo';
import { metaArrayToObject } from '@/utilities';
import NOTIFY_EXECUTOR_MUTATION from '@/graphql/mutations/NotifyExecutor';

export default {
  name: 'AppAccountSendNotification',
  mixins: [user, executors],
  data() {
    return {
      loadingIndexes: [],
    };
  },
  computed: {
    executorNotifications() {
      return this.executors.map((executor) => {
        return {
          id: executor.id,
          notified: metaArrayToObject(executor.meta).notified,
          email: executor.directoryPerson.defaultEmailAddress,
          name: executor.directoryPerson.fullName,
        };
      });
    },
  },
  methods: {
    ...mapActions('user/contacts', ['getDirectoryPersonsByOwnerId']),
    async sendExecutorNotification(index) {
      const executor = this.executorNotifications[index];

      if (!executor.email) {
        return;
      }

      try {
        this.loadingIndexes.push(index);
        await this.$apollo.mutate({
          mutation: NOTIFY_EXECUTOR_MUTATION,
          variables: {
            id: executor.id,
            email: executor.email,
            willId: this.willId,
          },
        });
        await this.getDirectoryPersonsByOwnerId({ ownerId: this.userId });
        await this.getExecutorsData();

        this.$nuxt.$emit('snackbar', {
          icon: 'success',
          type: 'success',
          text: this.$t('components.sendNotification.executorNotified'),
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingIndexes = this.loadingIndexes.filter(
          (loadingIndex) => loadingIndex !== index
        );
      }
    },
  },
};
