
import { mapActions, mapGetters } from 'vuex';
import { queries, mutations } from '@/modules/apollo-queries/poa-meta';
import { prevStep, nextStep } from '@/modules/poa-flow/medical';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliatePoaMedicalPreferences',
  mixins: [user, will],
  apollo: {
    poaMedicalMeta: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
  },
  data() {
    return {
      poaMedicalMeta: {
        has_preferences: undefined,
        preferences: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('user/contacts', ['userState']),
    previous() {
      return prevStep({
        currentUrl: window.location.pathname,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    isComplete() {
      return (
        this.poaMedicalMeta.has_preferences === false ||
        (this.poaMedicalMeta.has_preferences === true &&
          this.poaMedicalMeta.preferences?.length > 0)
      );
    },
  },
  methods: {
    ...mapActions('poa', ['setData']),
    onChange(value) {
      if (value === false) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      await this.$apollo.mutate(
        mutations.updatePoaMetas(this.poaId, [
          {
            key: 'has_preferences',
            value: this.poaMedicalMeta.has_preferences ? 'true' : 'false',
            category: 'MEDICAL',
          },
          {
            key: 'preferences',
            value: this.poaMedicalMeta.has_preferences
              ? this.poaMedicalMeta.preferences
              : null,
            category: 'MEDICAL',
          },
        ])
      );

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_module',
        props: {
          poa_id: this.poaId,
          module_name: 'Medical',
        },
      });

      const path = this.localePath(this.next);
      await this.$router.push({ path });
    },
  },
};
