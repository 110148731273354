
import { formatAUD, formatDate } from '@/utilities';
import orders from '@/mixins/apollo/orders';

export default {
  name: 'AppAccountOrders',
  mixins: [orders],
  data() {
    return {
      expandedOrders: [],
    };
  },
  methods: {
    formatDate,
    formatAUD(valueInCents) {
      return formatAUD(valueInCents / 100);
    },
    expandOrder(orderIndex) {
      this.$set(
        this.expandedOrders,
        orderIndex,
        !this.expandedOrders[orderIndex]
      );
      this.$refs[`expandOrder${orderIndex}`][0].blur();
    },
    collatedItems(items) {
      const products = [];
      const collatedItems = [];
      items.forEach((item) => {
        const product = item.product;
        const productIndex = products.indexOf(product);
        if (productIndex > -1) {
          collatedItems[productIndex].count += 1;
        } else {
          products.push(product);
          const lineItem = {
            product,
            count: 1,
            valueInCents: item.valueInCents,
          };
          collatedItems.push(lineItem);
        }
      });
      return collatedItems;
    },
    isFailedOrCancelled(status) {
      return ['FAILED', 'CANCELLED'].includes(status);
    },
  },
};
