
import { mapActions } from 'vuex';
import { will } from '@/mixins/apollo';
import { pollUntilTruthy } from '@/utilities';

export default {
  name: 'AppWillSkipApprovalModal',
  mixins: [will],
  data: () => ({
    isSubmitting: false,
    submissionIsSkippingApproval: false,
  }),
  methods: {
    ...mapActions('will', ['submitWill']),
    close() {
      this.$emit('closeModal');
    },
    handleSubmit() {
      this.isSubmitting = true;
      this.$emit('submit');
      this.isSubmitting = false;
      this.$router.push({ path: this.localePath('/will') });
      this.close();
    },
    pollForFileId() {
      return pollUntilTruthy(async () => {
        await this.refetchWill();
        return !!this.will.willFileId;
      });
    },
    async resubmitWillWithoutApproval() {
      this.isSubmitting = true;
      this.submissionIsSkippingApproval = true;

      if (this.willMeta.notify_charities === false) {
        this.willMeta.notify_charities = null;
        await this.updateWillMeta();
      }

      try {
        const willData = await this.submitWill(true);
        if (willData) {
          const fileHasBeenGenerated = await this.pollForFileId();
          if (fileHasBeenGenerated) {
            this.submissionIsSkippingApproval = false;
            this.$router.push({ path: this.localePath('/will') });
            this.close();
          }
        }
      } catch (error) {
        // resubmit will if failed without skipping approval
        await this.submitWill();
        this.$nuxt.$emit('snackbar', {
          placement: 'top-right',
          type: 'error',
          text: this.$t('pages.will.reviewPrompt.downloadError'),
        });
        this.submissionIsSkippingApproval = false;
        this.$router.push({ path: this.localePath('/will') });
        this.close();

        console.error(error);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
