
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { charities, gifts, user, will } from '@/mixins/apollo';
import { objectToMetaArray, metaArrayToObject } from '@/utilities';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';
import ADD_GIFT_MUTATION from '@/graphql/mutations/AddGift';
import UPDATE_GIFT_MUTATION from '@/graphql/mutations/UpdateGift';

export default {
  name: 'AppWillGiftWizard',
  mixins: [charities, gifts, user, will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      selectedContacts: [],
      willTierTwoAddOnIsVisible: false,
    };
  },
  computed: {
    ...mapFields('gift', [
      'fields.type',
      'fields.description',
      'fields.amount',
      'fields.note',
    ]),
    ...mapGetters('gift', [
      'id',
      'fields',
      'recipientDirectoryPerson',
      'charity',
    ]),
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('user/contacts', ['contacts']),
    showSuggestedCharities() {
      return !this.charities.length;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
        recipientDirectoryPersonId: null,
        charity: null,
        willId: this.willId,
      };

      if (this.id) {
        variables.id = this.id;

        if (this.note.length === 0) {
          variables.meta.push({ key: 'note', value: null });
        }
      } else {
        variables.meta = variables.meta.filter((meta) => meta.value);
      }

      const recipient = this.selectedContacts[0];

      if (recipient) {
        const recipientIsContact = this.contacts.some(
          (contact) => contact.id === recipient
        );
        if (recipientIsContact) {
          variables.recipientDirectoryPersonId = recipient;
        } else if (this.isCharity(recipient)) {
          variables.charity = recipient;
        }
      }

      return variables;
    },
    complete() {
      return (
        this.type &&
        ((this.type === 'money' && this.amount) ||
          (this.type === 'possession' && this.description)) &&
        this.selectedContacts.length
      );
    },
  },
  mounted() {
    this.selectedContacts = [
      this.recipientDirectoryPerson?.id || this.charity?.id,
    ].filter(Boolean);
    this.unWatchNote = this.$watch(
      () => {
        return this.note;
      },
      this.setLegalAdviceVisibility,
      { immediate: true }
    );
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    ...mapActions('application/ui', ['hideGiftWizard']),
    setGiftType(giftType) {
      this.type = giftType;
    },
    setLegalAdviceVisibility() {
      if (this.note?.length) {
        this.willTierTwoAddOnIsVisible = true;
        if (this.unWatchNote) {
          this.unWatchNote();
        }
      }
    },
    tabAttributes(giftType) {
      const active = giftType === this.type;
      return {
        active,
        level: active ? 'option' : 'secondary',
      };
    },
    async submit() {
      const noRecipientID =
        !this.variables.recipientDirectoryPersonId && !this.variables.charity;
      if (noRecipientID) {
        const newRecipientABN = this.selectedContacts[0].abn;
        await this.addPartnerCharity(this.selectedContacts[0]);
        await this.refetchCharities();
        const newRecipient = this.charities.find((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          return charityMeta.abn === newRecipientABN;
        });
        this.selectedContacts = [newRecipient.id];
      }
      try {
        const { data } = await this.$apollo.mutate({
          mutation: this.id ? UPDATE_GIFT_MUTATION : ADD_GIFT_MUTATION,
          variables: this.variables,
        });

        if (data.addGift) {
          await this.setGifts([...this.gifts, data.addGift]);
        } else if (data.updateGift) {
          const index = this.gifts.findIndex(
            (gift) => gift.id === data.updateGift.id
          );
          const newGifts = [...this.gifts];
          newGifts.splice(index, 1, data.updateGift);
          await this.setGifts(newGifts);
        }

        this.hideGiftWizard();
      } catch (error) {
        console.error(error);
        this.$refs.form.setGenericError('assigningContacts');
      }
    },
  },
};
