
import { mapActions, mapGetters } from 'vuex';
import {
  queries as metaQueries,
  mutations as metaMutations,
} from '@/modules/apollo-queries/poa-meta';
import {
  queries as inclusionsQueries,
  mutations as inclusionsMutations,
} from '@/modules/apollo-queries/poa-inclusions';
import { queries as attorneysQueries } from '@/modules/apollo-queries/poa-attorneys';
import { prevStep, nextStep } from '@/modules/poa-flow/financial';
import { will } from '@/mixins/apollo';

import { RELATIONSHIP_TYPE } from '@/utilities/constants';
export default {
  name: 'PagesAffiliatePoaFinancialSpending',
  mixins: [will],
  apollo: {
    attorneys: {
      ...attorneysQueries.getAttorneys({ power: 'FINANCIAL' }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setAttorneys(data.poa);
      },
    },
    poaFinancialMeta: {
      ...metaQueries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setData(data.poa);
      },
    },
    inclusionContacts: {
      ...inclusionsQueries.getInclusionContacts({
        power: 'FINANCIAL',
      }),
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        this.setInclusions(data.poa);
      },
    },
  },
  data() {
    return {
      RELATIONSHIP_TYPE,
      attorneys: undefined,
      inclusionContacts: [],
      selectedContacts: [],
      poaFinancialMeta: {
        has_inclusions: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('poa', ['poaId']),
    ...mapGetters('user/contacts', ['userDetails', 'contacts']),
    previous() {
      return prevStep({
        addressState: this.userState,
        currentUrl: window.location.pathname,
        poaFinancialMeta: this.poaFinancialMeta,
        numPrimaryAttorneysSelected: this.attorneys?.filter(
          (attorney) => attorney.role === 'PRIMARY'
        ).length,
      });
    },
    next() {
      return nextStep({
        currentUrl: window.location.pathname,
      });
    },
    isComplete() {
      return (
        this.poaFinancialMeta.has_inclusions === false ||
        (this.poaFinancialMeta.has_inclusions === true &&
          this.selectedContacts.length > 0)
      );
    },
    userState() {
      return this.userDetails?.residentialAddress?.region;
    },
  },
  watch: {
    inclusionContacts(inclusionContacts) {
      if (inclusionContacts.length && !this.selectedContacts.length) {
        this.selectedContacts = inclusionContacts.map((contact) => contact.id);
      }
    },
  },
  methods: {
    ...mapActions('poa', ['setAttorneys', 'setData', 'setInclusions']),
    onChange(value) {
      if (value === false) {
        this.submit();
      }
    },
    async submit() {
      try {
        await Promise.all([
          this.$apollo.mutate(
            metaMutations.updatePoaMeta(this.poaId, {
              key: 'has_inclusions',
              value: this.poaFinancialMeta.has_inclusions ? 'true' : 'false',
              category: 'FINANCIAL',
            })
          ),
          this.$apollo.mutate(
            inclusionsMutations.setInclusionContacts({
              directoryPersonIds: this.selectedContacts,
              power: 'FINANCIAL',
              poaId: this.poaId,
            })
          ),
        ]);
        await this.$apollo.queries.inclusionContacts.refetch();
        const path = this.localePath(this.next.path);
        this.$router.push({ path });
      } catch (error) {
        console.error(error);
        this.$refs.form.setGenericError('assigningContacts');
      }
    },
  },
};
