import { render, staticRenderFns } from "./index.vue?vue&type=template&id=55c83202"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=55c83202&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppBadge: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Badge.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,WpVerticalSelectButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/VerticalSelectButton.vue').default,AppForm: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Form.vue').default,AppModuleStep: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ModuleStep.vue').default})
