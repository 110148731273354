
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PagesAffiliatePoaAboutYourselfDateOfBirth',
  computed: {
    ...mapGetters('user/contacts', ['userDetails']),
    ...mapFields('user/contacts', ['user.dateOfBirth']),
  },
  methods: {
    ...mapActions('user/contacts', ['updateDirectoryPerson']),
    ...mapActions('application/prices', ['getProductPrices']),
    ...mapActions('user/cart', ['getCartItems']),
    async updateUserDateOfBirth() {
      await this.updateDirectoryPerson({
        id: this.userDetails.id,
        dateOfBirth: this.dateOfBirth,
      });

      await Promise.all([this.getProductPrices(), this.getCartItems()]);

      this.$nuxt.$emit('sendTrackingAttributes', {
        date_of_birth: `${this.userDetails.dateOfBirth}`,
        age: this.userDetails.age,
      });

      this.$router.push({
        path: this.localePath('/poa/about-yourself/phone'),
      });
    },
  },
};
