import { render, staticRenderFns } from "./InviteFriendsAndFamily.vue?vue&type=template&id=3c04f4fc"
import script from "./InviteFriendsAndFamily.vue?vue&type=script&lang=js"
export * from "./InviteFriendsAndFamily.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppAccountSendInvite: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/account/SendInvite.vue').default,AppAccountSentInvite: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/account/SentInvite.vue').default})
