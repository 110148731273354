
import { mapActions, mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';

export default {
  name: 'AppWillPowerOfAttorneyAddOn',
  props: {
    pageRequirementsMet: {
      type: Boolean,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalIsVisible: false,
      productHasBeenAddedToCart: false,
      faqs: this.$t('components.powerOfAttorneyAddOn.modal.faqs'),
    };
  },
  computed: {
    ...mapGetters('user/cart', ['cartItemCodes', 'isInCart', 'canAddToCart']),
    ...mapGetters('user/contacts', ['userIsAustralian']),
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters('application/prices', ['poaPrice']),
    price() {
      return this.formatPrice(this.poaPrice);
    },
    productIsInCart() {
      return this.isInCart('POA');
    },
    addonIsVisible() {
      return (
        this.userIsAustralian &&
        this.features.powerOfAttorneyEnabled &&
        (this.canAddToCart('POA') || this.productIsInCart) &&
        (this.pageRequirementsMet || this.productIsInCart)
      );
    },
  },
  methods: {
    formatPrice,
    ...mapActions('user/cart', ['addToCart']),
    async addPowerOfAttorneyToCartFromModal() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'add_on_actioned',
        props: {
          page_path: this.$route.path,
          product_code: 'POA',
        },
      });
      await this.addPowerOfAttorneyToCart();
    },
    async addPowerOfAttorneyToCart() {
      await this.addToCart({ codes: ['POA'], showSnackbar: false });
      this.modalIsVisible = false;
      this.productHasBeenAddedToCart = true;
    },
    showPowerOfAttorneyModal() {
      this.modalIsVisible = true;
    },
  },
};
