
import { mapGetters } from 'vuex';

export default {
  name: 'AppDashboardPowerOfAttorneyGateway',
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('user/contacts', ['userIsAustralian']),
    ...mapGetters('modules', ['poaModules']),
    ...mapGetters('poa', { poaStatus: 'status' }),
    ...mapGetters('progress', { poaProgress: 'poa' }),
    primaryCtaList() {
      if (this.poaStatus === 'APPROVED') {
        return [
          {
            link: '/poa',
            text: this.$t('components.powerOfAttorneyGateway.downloadMyPoa'),
            icon: 'pi-download',
          },
          {
            link: '/poa',
            text: this.$t('components.powerOfAttorneyGateway.updateMyPoa'),
            icon: 'pi-pencil',
            class: 'p-button-outlined',
          },
        ];
      }
      if (!this.poaStatus) {
        return [
          {
            link: this.userIsAustralian ? '/poa' : '/onboarding/poa',
            text: this.ctaText,
          },
          ...(this.userIsAustralian
            ? [
                {
                  link: '/poa',
                  text: this.$t('components.powerOfAttorneyGateway.learnMore'),
                  class: 'p-button-outlined',
                  icon: null,
                },
              ]
            : []),
        ];
      }
      return [
        {
          link: '/poa',
          text: this.ctaText,
        },
      ];
    },
    itemsCta() {
      return {
        link: this.userIsAustralian ? '/poa' : '/onboarding/poa',
        text: this.ctaText,
      };
    },
    description() {
      if (this.poaStatus === 'APPROVED') {
        return this.$t('components.powerOfAttorneyGateway.approved');
      }
      return this.$t('components.powerOfAttorneyGateway.incomplete');
    },
    ctaText() {
      if (this.poaProgress.decimal > 0) {
        if (this.poaProgress.complete) {
          return this.$t('components.powerOfAttorneyGateway.submitPoa');
        } else if (this.poaProgress.decimal === 1) {
          return this.$t('components.powerOfAttorneyGateway.downloadMyPoa');
        }
        return this.$t('components.powerOfAttorneyGateway.update');
      }
      return this.userIsAustralian
        ? this.$t('commons.text.startPoa')
        : this.$t('components.powerOfAttorneyGateway.learnMore');
    },
    itemsDescription() {
      let totalModules = 0;
      let completedModules = 0;
      Object.keys(this.poaProgress.modules).forEach((module) => {
        if (module !== 'review') {
          totalModules++;
          completedModules += Number(
            this.poaProgress.modules[module].decimal === 1
          );
        }
      });
      return `${completedModules} ${this.$t(
        'components.powerOfAttorneyGateway.outOf'
      )} ${totalModules} ${this.$t(
        'components.powerOfAttorneyGateway.areComplete'
      )}`;
    },
    items() {
      return this.poaModules.map(({ id, key, displayName, path: cta }) => ({
        title: this.$t(displayName),
        status: this.progressToStatus(key),
        cta: this.poaStatus === 'IN_PROGRESS' ? cta : '/poa',
        id,
      }));
    },
  },
  methods: {
    progressToStatus(key) {
      const decimal = this.poaProgress.modules[key].decimal;
      if (!decimal) {
        return 'not-started';
      } else if (decimal === 1) {
        return 'completed';
      } else {
        return 'in-progress';
      }
    },
  },
};
