
import { mapActions, mapGetters } from 'vuex';
import { IDENTIFIER_TYPE, RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  name: 'OrganismsContactSelector',
  layout: 'ruach',
  props: {
    max: {
      type: Number,
      default: Infinity,
    },
    relationshipType: {
      type: String,
      default: null,
    },
    contactLabel: {
      type: String,
      default: null,
    },
    overEighteen: {
      type: Boolean,
      default: false,
    },
    dobRequired: {
      type: Boolean,
      default: false,
    },
    phoneRequired: {
      type: Boolean,
      default: false,
    },
    emailRequired: {
      type: Boolean,
      default: false,
    },
    addressRequired: {
      type: Boolean,
      default: false,
    },
    emailRequiredForInvite: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    value: {
      default: () => [],
      type: Array,
    },
    valueLocked: {
      type: Boolean,
      default: false,
    },
    excludedContacts: {
      default: () => [],
      type: Array,
    },
    excludedContactsLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      IDENTIFIER_TYPE,
      contactSelectionIsVisible: false,
      contactFormIsVisible: false,
      editingContact: {},
    };
  },
  computed: {
    ...mapGetters('user/contacts', ['contacts', 'userPartner']),
    ...mapGetters('invites', ['invites']),
    partnerId() {
      return this.userPartner ? this.userPartner.id : null;
    },
    selectedContacts: {
      get() {
        return this.contacts.filter((contact) =>
          this.value.includes(contact.id)
        );
      },
      set(contacts) {
        this.$emit(
          'input',
          contacts.map((contact) => contact.id)
        );
      },
    },
    identifierRequirements() {
      const requirements = [];
      if (this.dobRequired) {
        requirements.push(IDENTIFIER_TYPE.DOB);
      }
      if (this.phoneRequired) {
        requirements.push(IDENTIFIER_TYPE.PHONE);
      }
      if (this.emailRequired) {
        requirements.push(IDENTIFIER_TYPE.EMAIL);
      }
      if (this.addressRequired) {
        requirements.push(IDENTIFIER_TYPE.ADDRESS);
      }
      return requirements;
    },
    buttonVerbage() {
      return [RELATIONSHIP_TYPE.PARTNER, RELATIONSHIP_TYPE.CHILD].includes(
        this.relationshipType
      )
        ? 'add'
        : 'select';
    },
    removeRelationshipOnRemove() {
      return [RELATIONSHIP_TYPE.PARTNER, RELATIONSHIP_TYPE.CHILD].includes(
        this.relationshipType
      );
    },
  },
  mounted() {
    this.getInvites();
  },
  methods: {
    ...mapActions('invites', ['getInvites']),
    addContact() {
      if (
        [RELATIONSHIP_TYPE.PARTNER, RELATIONSHIP_TYPE.CHILD].includes(
          this.relationshipType
        )
      ) {
        this.openContactForm();
      } else {
        this.openContactSelection();
      }
    },
    openContactSelection() {
      this.contactSelectionIsVisible = true;
    },
    closeContactSelection() {
      this.contactSelectionIsVisible = false;
    },
    updateSelectedContacts(contacts) {
      this.selectedContacts = contacts;
    },
    openContactForm(contact) {
      if (contact?.id) {
        this.editingContact = contact;
      } else {
        this.editingContact = {};
      }
      this.contactFormIsVisible = true;
    },
    closeContactForm(newContact) {
      if (newContact) {
        if (this.$refs.contactSelection) {
          this.$refs.contactSelection.selectNewContact(newContact);
        } else {
          this.updateSelectedContacts([...this.selectedContacts, newContact]);
        }
      }
      this.contactFormIsVisible = false;
    },
  },
};
