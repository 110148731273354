
import { makePartnerLogoUrl } from '@/utilities';

export default {
  name: 'AppCharity',
  props: {
    logo: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: '',
    },
    useTooltip: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    makePartnerLogoUrl,
  },
};
