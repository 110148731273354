
import RuLogo from 'ruach/atoms/Logo';
import { mapActions, mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';

export default {
  name: 'AppWillTierTwoAddOn',
  components: {
    RuLogo,
  },
  props: {
    pageRequirementsMet: {
      type: Boolean,
      required: true,
    },
    module: {
      type: String,
      required: true,
      validator: (value) => ['assets', 'estate', 'gifts'].includes(value),
    },
  },
  data() {
    return {
      willTierTwoUpgradeModalIsVisible: false,
      productHasBeenAddedToCart: false,
      justAddedToCart: false,
    };
  },
  computed: {
    ...mapGetters('user/cart', ['cartItemCodes', 'isInCart', 'canAddToCart']),
    ...mapGetters('user/contacts', ['userIsAustralian']),
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters('application/prices', ['willTierTwoPrice']),
    price() {
      return this.formatPrice(this.willTierTwoPrice);
    },
    productIsInCart() {
      return this.isInCart('WILL_TIER_TWO');
    },
    addonIsVisible() {
      if (this.justAddedToCart) {
        return true;
      }
      return (
        this.userIsAustralian &&
        this.features.willTierTwoEnabled &&
        !this.productIsInCart &&
        this.canAddToCart('WILL_TIER_TWO') &&
        this.pageRequirementsMet
      );
    },
  },
  methods: {
    formatPrice,
    ...mapActions('user/cart', ['addToCart']),
    ...mapActions('will-tiers', ['addWillTierTwoToCart']),
    async upgradeToWillTierTwo() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'click',
        props: {
          element_id: 'will-tier-two-add-on--upgrade',
          page_path: this.$route.path,
        },
      });
      await this.addWillTierTwoToCart();
      this.showAddedMessage();
    },
    showAddedMessage() {
      this.willTierTwoUpgradeModalIsVisible = false;
      this.productHasBeenAddedToCart = true;
      this.justAddedToCart = true;
    },
    showWillTierTwoUpgradeModal() {
      this.willTierTwoUpgradeModalIsVisible = true;
    },
  },
};
