import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=8c6d77b8"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppTip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tip.vue').default,AppWillEstateBackupSelector: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/EstateBackupSelector.vue').default,AppContactSelector: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ContactSelector.vue').default,AppWillCharitySuggester: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/CharitySuggester.vue').default,AppWillCharitySelector: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/CharitySelector.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppWillCauseSelector: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/CauseSelector.vue').default,AppEstateCharityPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/EstateCharityPanel.vue').default,AppHelp: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/Help.vue').default,AppForm: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Form.vue').default,AppModuleStep: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ModuleStep.vue').default})
