import { render, staticRenderFns } from "./index.vue?vue&type=template&id=b3bd2040"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppCheckout: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/checkout/index.vue').default,AppCheckoutExitModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/checkout/ExitModal.vue').default})
