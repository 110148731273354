
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PagesAffiliateCheckoutCartThankYou',
  data() {
    return {
      inviteModalIsVisible: false,
    };
  },
  computed: {
    ...mapGetters('orders', ['isLatestProductPurchased']),
    ...mapGetters(['willStatus', 'isFreeWillWeek']),
    ...mapGetters('application/affiliate', ['features']),
    ...mapGetters('poa', { poaStatus: 'status' }),
    ...mapGetters('progress', { willProgress: 'will', poaProgress: 'poa' }),
    isWillGatewayVisible() {
      return (
        this.features.willEnabled && this.willStatus !== 'AWAITING_APPROVAL'
      );
    },
    isPoaGatewayVisible() {
      return (
        this.features.powerOfAttorneyEnabled &&
        !['APPROVED', 'AWAITING_APPROVAL'].includes(this.poaStatus)
      );
    },
    isVaultGatewayVisible() {
      return this.features.endOfLifeEnabled;
    },
    isInformedRoleProduct() {
      return ['WILL', 'WILL_TIER_TWO'].some((product) =>
        this.isLatestProductPurchased(product)
      );
    },
  },
  mounted() {
    this.getInvites();
  },
  methods: {
    ...mapActions('invites', ['getInvites']),
    openInviteModal() {
      this.inviteModalIsVisible = true;
    },
    closeInviteModal() {
      this.inviteModalIsVisible = false;
    },
    openContactInformedTab() {
      const typeform = 'https://d8oxlj2jz8d.typeform.com/to/hPAnrfG2';
      window.open(typeform, '_blank', 'noopener, noreferrer');
    },
  },
};
