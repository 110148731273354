
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import shuffle from 'lodash/shuffle';
import { deburr } from 'lodash/string';
import { charityCategories } from '@/modules/charityData';
import { charities, will, user, beneficiaries } from '@/mixins/apollo';
import { objectToMetaArray } from '@/utilities';
import ADD_CHARITY_MUTATION from '@/graphql/mutations/AddCharity';

export default {
  name: 'AppCharityPanel',
  mixins: [charities, will, user, beneficiaries],
  data() {
    return {
      charityCategories: {
        'All charities': 'All charities',
        ...charityCategories,
      },
      savingCharity: false,
      searchTerm: '',
      selectedCategory: 'All charities',
      selectedCharitySourceItemIds: [],
      showAddCustomCharity: false,
      ADD_CHARITY_MUTATION,
      newCharity: {
        name: '',
        abn: '',
        address: '',
      },
    };
  },
  computed: {
    ...mapFields('charity', ['fields.name', 'fields.address']),
    ...mapGetters(['charitySource', 'charityParents', 'featuredFive']),
    ...mapGetters('charity', ['id', 'fields']),
    ...mapGetters('application/ui', ['charityPanelIsOpen']),
    ...mapGetters('user/contacts', ['userState']),
    charityCategoryKeys() {
      const categories = Object.keys(charityCategories);

      return ['All charities', ...categories.sort((a, b) => a - b)];
    },
    shuffledCharitySource() {
      const regionalFeaturedFive = this.featuredFive.map((partnership) => {
        if (
          this.charityParents[partnership.id] &&
          this.userState &&
          this.charityParents[partnership.id][this.userState]
        ) {
          return this.charityParents[partnership.id][this.userState];
        }
        return partnership;
      });
      const featuredFiveIds = regionalFeaturedFive.map((charitySourceItem) => {
        return charitySourceItem.id;
      });
      const unfeaturedCharityArray = this.charitySource.filter(
        (charitySourceItem) => !featuredFiveIds.includes(charitySourceItem.id)
      );
      return shuffle(regionalFeaturedFive).concat(
        shuffle(unfeaturedCharityArray)
      );
    },
    filteredCharitySource() {
      const hasCategory = this.selectedCategory !== 'All charities';
      const hasSearchTerm = !!this.searchTerm;

      return this.shuffledCharitySource.filter((charitySourceItem) => {
        const sanitisedCharityName = deburr(charitySourceItem.name)
          .replaceAll('’', "'")
          .toUpperCase();
        const sanitisedCharityDisplayName = deburr(
          charitySourceItem.displayName
        )
          .replaceAll('’', "'")
          .toUpperCase();
        const sanitisedSearchTerm = hasSearchTerm
          ? deburr(this.searchTerm).replaceAll('’', "'").toUpperCase()
          : '';

        const isSearchTermMatched = hasSearchTerm
          ? sanitisedCharityDisplayName.includes(sanitisedSearchTerm) ||
            sanitisedCharityName.includes(sanitisedSearchTerm)
          : true;

        return (
          (hasCategory
            ? charitySourceItem.categories?.includes(this.selectedCategory)
            : true) && isSearchTermMatched
        );
      });
    },
    newCharityData() {
      return {
        willId: this.willId,
        meta: objectToMetaArray({
          name: this.newCharity.name,
          abn: this.newCharity.abn.trim(),
          address: this.newCharity.address,
        }),
      };
    },
  },
  methods: {
    ...mapActions('application/ui', ['hideCharityPanel']),
    addNewCharity(store, { data: { addCharity } }) {
      if (addCharity) {
        const data = store.readQuery(this.getCharitiesQuery);
        data.getCharities.push(addCharity);
        store.writeQuery({
          ...this.getCharitiesQuery,
          data,
        });
        this.close();
      }
    },
    toggleShowAddCustomCharity() {
      this.showAddCustomCharity = !this.showAddCustomCharity;
    },
    async saveCharities() {
      if (this.selectedCharitySourceItemIds.length) {
        this.savingCharity = true;
        const addedCharities = [];
        await Promise.all(
          this.selectedCharitySourceItemIds.map((id) => {
            const charitySourceItem = this.charitySource.find((c) => {
              return c.id === id;
            });

            const charityData = {
              ...charitySourceItem,
              searchLocation: window.location.pathname,
              searchFeature: this.featuredFive.some((c) => {
                return c.id === id;
              }),
            };

            addedCharities.push(charityData);
            return this.addPartnerCharity(charityData);
          })
        );

        await this.refetchCharities();
        this.savingCharity = false;
        this.$nuxt.$emit('addCharities', addedCharities);
        this.close();
      }
    },
    categoryIsSelected(category) {
      return this.selectedCategory === category
        ? 'bg-slate-300 text-white'
        : 'bg-white text-slate-300';
    },
    close() {
      this.searchTerm = '';
      this.selectedCategory = 'All charities';
      this.selectedCharitySourceItemIds = [];
      this.showAddCustomCharity = false;
      this.hideCharityPanel();
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
    primaryText() {
      return !this.hideName ? this.charityMeta.name : null;
    },
    secondaryText() {
      if (this.descriptionIsHidden) {
        return null;
      }
      return this.partnerCharity
        ? this.charityMeta.description
        : this.charityMeta.address;
    },
    tooltipText() {
      return this.showTooltip ? this.charityMeta.description : null;
    },
    classes() {
      return {
        card: {
          'border-2 border-teal-200': this.selected,
          'border-2 border-white': !this.selected,
        },
      };
    },
  },
};
